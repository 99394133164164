<template>
  <section class="section">
    <b-loading :is-full-page="false" v-model="loadingQuestDetails"></b-loading>

    <div v-if="selectedQuest">
      <div class="columns mb-6">
        <div class="column is-9">
          <h4 class="title is-4">{{ selectedQuest.title }}<br /><small>{{ selectedQuest.id }}</small></h4>
          <h6 class="title is-6">{{ selectedQuest.quest_story_id && `Story: ${selectedQuest.quest_story_id}` }}</h6>
        </div>
      </div>
      <b-tabs type="is-boxed" expanded>
        <b-tab-item label="Details">
          <quest-form :quest="selectedQuest"></quest-form>
        </b-tab-item>
        <b-tab-item :label="`Active Players (${selectedQuest.active_users.length})`">
          <quest-users title="Active Players" :users="selectedQuest.active_users"></quest-users>
        </b-tab-item>
        <b-tab-item :label="`Completed Players (${selectedQuest.completed_users.length})`">
          <quest-users title="Completed Players" :users="selectedQuest.completed_users"></quest-users>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import QuestForm from '@/components/Quests/QuestForm.vue';
import QuestUsers from '@/components/Quests/QuestUsers.vue';

export default {
  name: 'QuestDetails',
  components: {
    QuestUsers,
    QuestForm,
  },
  props: {
    quest: String,
  },
  computed: {
    ...mapState('quest', ['loadingQuestDetails', 'selectedQuest']),
  },
  mounted() {
    this.loadQuest(this.quest);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadQuest(to.params.quest);
    next();
  },
  methods: {
    loadQuest(id) {
      this.$store.dispatch('quest/loadQuestDetails', id);
    },
  },
};
</script>

<style lang="scss">
.stat-list > div {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  .user-stat-icon {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
}

</style>
